.container-hours {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #393839;
  box-sizing: border-box;
  /* border-botradius-bottom: 8px; */
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 40px;
  animation-duration: 0.5s;
  flex-wrap: wrap;
  border-top: none;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: 40px;
  padding-right: 40px;
  height: 250px;
  overflow: scroll;
  overflow-x: hidden;
}

.container-buttons-option-hours {
  width: 80px;
  height: 30px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  text-align: center;
  margin-top: 20px;
}

@media screen and (max-width: 630px) {
  .container-hours {
    padding: 0px;
    height: 225px;
  }
  .container-buttons-option-hours {
    margin-top: 10px;
  }
}
