.logo-company-san-jorge {
  width: 300px;
  height: 100px;
  margin-bottom: 3vh;
}

@media screen and (max-width: 630px) {
  .logo-company-san-jorge {
    width: 180px;
    height: 67px;
    margin-top: 0px !important;
  }
}
