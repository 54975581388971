.contain-user-name {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.text-user-name {
  background-color: #29a1dc;
  border-radius: 8px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
  padding: 8px;
  color: #ffffff;
  font-size: 15px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
