.card-resumen {
  width: 600px;
  height: 390px;
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(57, 56, 57, 0.1);
  border-radius: 10px;
  animation-duration: 1s;
}

.title-card-resumen {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin: 40px 40px 20px 40px;
}

.text-type-resumen {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #393839;
  margin-left: 40px;
}

.contain-btns-resumen {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 25px;
}

.text-date-hours {
  font-family: Gotham;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #393839;
}

.containerFechaHorarioText {
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerFechaHorarioText div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
}

.icon-resumen {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.title-car {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 29px;
  color: #000000;
  margin: 40px 40px 20px 40px;
}

.text-info-car {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #393839;
}

@media screen and (max-width: 630px) {
  .contain-btns-resumen {
    flex-direction: column;
  }
  .card-resumen {
    width: 345px;
    height: 490px;
  }
  .text-info-car {
    font-size: 12px;
  }
  .text-type-resumen {
    font-size: 12px;
  }
  .text-date-hours {
    font-size: 10px;
  }
  .icon-resumen {
    width: 18px;
    height: 18px;
  }
}
