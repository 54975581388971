.custom-btn {
  width: 200px;
  height: 50px;
  border-radius: 8px;
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  border-radius: 8px;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
}

.btn-send-appointment {
  outline: none;
  border: none;
  height: 50px;
  /* background: #e2e2e2; */
  border-radius: 8px;
  font-family: Gotham;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
}

@media screen and (max-width: 630px) {
  .custom-btn {
    width: 270px;
    margin: 0px !important;
    margin-bottom: 5px !important;
  }
}
