.container-location-inspect {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.select-location-inspect {
  margin-bottom: 20px;
  margin-top: 10px;
  width: 100%;
  height: 40px;
  outline: none;
  border-radius: 8px;
}

.container-options-location {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border: 1px solid #393839;
  box-sizing: border-box;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 20px;
  margin-top: -10px;
  flex-wrap: wrap;
  border-top: none;
  padding-top: 15px;
  padding-bottom: 5px;
}
.divider-locations {
  width: 100%;
  height: 1px;
  background-color: #393839;
  margin-top: 5px;
  margin-bottom: 5px;
}

.text-location {
  margin-left: 20px;
  cursor: pointer;
  font-size: 12px !important;
}
