.title-header-modal {
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 160%;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}

.contain-btn-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 630px) {
  .contain-btn-modal {
    flex-direction: column;
  }
}
