.input-plate {
  height: 40px;
  outline: none;
  border: 1px solid #393839;
  border-radius: 8px;
  margin: 10px 0px 20px 0px;
  padding-left: 20px;
  font-size: 14px;
}

.input-plate::placeholder {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  color: #96989a;
}
