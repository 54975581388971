.contain-select {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid #393839;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
}

.label-inputs {
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.value-inputs {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #393839;
  margin-left: 20px;
}

.placeholder-inputs {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #96989a;
  margin-left: 20px;
}

.arrow-select {
  width: 12px;
  height: 7px;
  margin-right: 20px;
}
