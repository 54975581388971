.container-app {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: url("./../images/san-jorge/background-san-jorge.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100vw 60vh;
}

.card-appointment {
  width: 600px;
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(57, 56, 57, 0.1);
  border-radius: 10px;
  margin-bottom: 5vh;
}

.title-card {
  font-family: Gotham;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
  margin: 40px 40px 30px 40px;
}

.check {
  width: 18px;
  height: 14px;
}

.subcontainer-card {
  margin: 0px 40px 40px 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.displayNone {
  display: none;
}

/* ------------------ MEDIA QUERY ------------------------- */

@media screen and (max-width: 630px) {
  .subcontainer-card {
    margin: 0px 20px 20px 20px;
  }
  .title-card {
    margin-left: 20px;
  }

  .card-appointment {
    width: 345px;
    margin-bottom: 10px;
  }
}
