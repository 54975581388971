.DayPicker {
  display: inline-block;
  font-size: 14px;
  margin-top: 15px;
}

html {
  font-size: 62.5%;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

@font-face {
  font-family: Gotham;
  src: url("Gotham/Gotham-Book.otf") format("truetype");
  src: url("Gotham/gotham-book.woff") format("woff");
  src: url("Gotham/gotham-book.woff2") format("woff2");
}

body {
  font-family: "Gotham", sans-serif;
  margin: 0;
  padding: 0;
}
p {
  padding: 0;
  margin: 0;
}

.swal2-popup {
  font-size: 1.4rem !important;
}

.container-login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-login {
  width: 320px;
  height: 136px;
  background: #ffffff;
  /* border: 1px solid #e1e7ef; */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 20px rgb(57 56 57 / 10%);
}

.btn-login-google {
  width: 280px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: #29a1dc;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  cursor: pointer;
}
.btn-login-google:hover {
  background: #2183b2;
}

.google-icon-login {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.title-login {
  font-weight: 600;
  font-size: 30px;
  line-height: 150%;
  color: #1b2130;
  margin-bottom: 10px;
}

.containerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

input#paracaidas {
  width: 20px;
  height: 20px;
}

.label-paracaidas {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
